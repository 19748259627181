export default {
    data() {
        return {
            sortIdentifier: "default"
        };
    },
    watch: {
        sortBy: {
            handler(val) {
                this.$store.dispatch("sort/setSortBy", {
                    key: this.sortIdentifier,
                    sortBy: val
                });
            },
            deep: true
        },
        sortDesc: {
            handler(val) {
                this.$store.dispatch("sort/setSortDesc", {
                    key: this.sortIdentifier,
                    sortDesc: val
                });
            },
            deep: true
        }
    },
    created() {
        const sort = this.$store.getters["sort/getSort"](this.sortIdentifier);
        this.sortBy = sort.sortBy;
        this.sortDesc = sort.sortDesc;
    }
};
