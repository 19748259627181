<template>
    <MainContainer>
        <div class="forms">
            <h2>Formulare</h2>
            <b-row class="my-4">
                <b-col md="9">
                    <b-row>
                        <b-col cols="12">
                            <h5>Filter</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <h6>Tags</h6>
                            <CheckboxSelect
                                :options="tagsSelect"
                                v-model="filter.tags"
                                :size="size"
                            />
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col>
                            <b-button :size="size" @click="applyFilter"
                                >Filter anwenden</b-button
                            >
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="3">
                    <h5>Funktionen</h5>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        :to="{ path: `/forms/new` }"
                        >Neues Formular</b-button
                    >
                    <b-button
                        class="ml-1"
                        variant="outline-danger"
                        size="sm"
                        :disabled="!(selected.length > 0)"
                        @click="openDeleteModal({ multi: true })"
                        >Auswahl löschen</b-button
                    >
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="my-4">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>
            <b-table
                :items="forms"
                :fields="currentSelectedFields"
                :filter="filter.pattern"
                :filter-included-fields="['form_url', 'id', 'tags', 'title', 'token', 'modification_date']"
                :busy="busy.data"
                :small="size == 'sm'"
                select-mode="multi"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                ref="selectableTable"
                empty-text="Keine Daten vorhanden"
                empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
                foot-clone
                no-footer-sorting
                selectable
                bordered
                striped
                responsive
                show-empty
                @row-selected="onRowSelected"
                @sort-changed="sortChanged"
            >
                <template #thead-top="{ columns }">
                    <b-tr>
                        <b-td>
                            <p class="small m-0">Gesamt: {{ forms.length }}</p>
                            <p class="small m-0">
                                Gewählt:
                                <template v-if="selected.length > 0"
                                    >{{ selected.length }}
                                </template>
                                <template v-else>-</template>
                            </p>
                        </b-td>
                        <b-td :colspan="currentRecordFields.length"></b-td>
                        <b-td class="text-right"
                            ><b-dropdown
                                variant="outline-secondary"
                                text="Spaltenauswahl"
                                :size="size"
                            >
                                <b-dropdown-form>
                                    <b-form-checkbox-group
                                        v-model="selectedFields"
                                        :options="recordFields"
                                        value-field="key"
                                        text-field="label"
                                    ></b-form-checkbox-group>
                                </b-dropdown-form>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </template>

                <template #table-busy>
                    <div class="text-center text-secondary my-2">
                        <b-spinner class="mr-1 align-middle" small></b-spinner>
                        <strong>Daten werden geladen...</strong>
                    </div>
                </template>

                <template #head(__selected)>
                    <input
                        type="checkbox"
                        v-model="selectAll"
                        :true-value="true"
                        :false-value="false"
                        @change="toggleAllSelectedRows"
                    />
                </template>

                <template #cell(__selected)="{ rowSelected }">
                    <input
                        type="checkbox"
                        :checked="rowSelected"
                        :true-value="true"
                        :false-value="false"
                        disabled
                    />
                </template>

                <template #cell(title)="data">
                    <b-link :to="{ path: `/forms/${data.item.id}#answers` }">{{
                        data.value
                    }}</b-link>
                </template>

                <template #cell(is_active)="data">
                    <template v-if="data.value == 1">
                        <font-awesome-icon icon="check" class="text-success" />
                    </template>
                    <template v-else>
                        <font-awesome-icon icon="times" class="text-danger" />
                    </template>
                </template>

                <template #cell(disable_by_count)="data">
                    <template v-if="data.value == 1">
                        <font-awesome-icon icon="check" class="text-success" />
                        ({{ data.item.disable_count }})
                    </template>
                    <template v-else>
                        <i class="text-muted small">Keine Beschränkung</i>
                    </template>
                </template>

                <template #cell(disable_by_date)="data">
                    <template v-if="data.value == 1">
                        <font-awesome-icon icon="check" class="text-success" />
                        ({{ formatDate(data.item.disable_date) }})
                    </template>
                    <template v-else>
                        <i class="text-muted small">Keine Beschränkung</i>
                    </template>
                </template>

                <template #cell(actual_active)="data">
                    <template v-if="data.value">
                        <font-awesome-icon icon="circle" class="text-success" />
                    </template>
                    <template v-else>
                        <font-awesome-icon icon="circle" class="text-danger" />
                    </template>
                </template>

                <template #cell(tags)="data">
                    <b-badge
                        v-for="tag_id in data.value"
                        :key="tag_id"
                        class="mx-1"
                    >
                        {{ tagName(tag_id) }}
                    </b-badge>
                </template>

                <template #cell(modification_date)="data">
                    {{ formatDateTime(data.value) }}
                </template>

                <template #cell(form_url)="data">
                    <b-button
                        :size="size"
                        :href="data.value"
                        variant="outline-secondary"
                        target="_blank"
                        >Formular öffnen</b-button
                    >
                </template>

                <template #cell(answer_sum)="data">
                    <div class="text-right">{{ data.value }}</div>
                </template>

                <template #cell(__actions)="data">
                    <b-button
                        variant="outline-info"
                        size="sm"
                        class="mx-1"
                        @click="openConfirmDuplicateModal(data.item.id)"
                        >Duplizieren</b-button
                    >
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        :to="{ path: `/forms/${data.item.id}` }"
                        >Bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal({ record: data.item })"
                        >Löschen</b-button
                    >
                </template>
            </b-table>

            <ConfirmModal
                id="confirm-duplicate-modal"
                title="Formular duplizieren"
                :busy="busy.mutation"
                @ok="duplicateForm"
            >
                Das Formular mit dem Titel "{{ formName(duplicateId) }}"
                wirklich duplizieren?
            </ConfirmModal>

            <DeleteModal
                :mode="modificationMode"
                :record="modificationRecord"
                :fields="currentRecordFields"
                :selected="selected"
                :busy="busy.mutation"
                @ok="deleteRecord"
            >
            </DeleteModal>
        </div>
    </MainContainer>
</template>

<script>
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import DateFormat from "@/mixins/Date/Date";
import Filter from "@/mixins/Filter/Filter";
import Sort from "@/mixins/Sort/Sort";
import TableFields from "@/mixins/TableFields/TableFields";

import MainContainer from "@/components/MainContainer/MainContainer";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import ConfirmModal from "@/components/ConfirmModal/ConfirmModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import { mapActions, mapGetters } from "vuex";

import "./Forms.scss";

export default {
    name: "Forms",
    components: {
        MainContainer,
        FormGroupBuilder,
        TableExport,
        TableFilter,
        ConfirmModal,
        DeleteModal,
        CheckboxSelect
    },
    mixins: [ModelView, Size, DateFormat, Filter, Sort, TableFields],
    data() {
        return {
            duplicateId: null,
            filter: {
                pattern: null,
                tags: []
            },
            tableFieldsIdentifier: "forms",
            filterIdentifier: "forms",
            sortIdentifier: "forms",
            sortBy: "modification_date",
            sortDesc: true,
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "title",
                    label: "Titel",
                    sortable: true
                },
                {
                    key: "is_active",
                    label: "aktiv",
                    class: "text-center"
                },
                {
                    key: "disable_by_count",
                    label: "Teilnahmebeschränkung",
                    class: "text-center"
                },
                {
                    key: "disable_by_date",
                    label: "Zeitliche Beschränkung",
                    class: "text-center"
                },
                {
                    key: "actual_active",
                    label: "Status",
                    class: "text-center"
                },
                {
                    key: "tags",
                    label: "Tags"
                },
                {
                    key: "modification_date",
                    label: "Änderungsdatum",
                    sortable: true
                },
                {
                    key: "form_url",
                    label: "URL"
                },
                {
                    key: "answer_sum",
                    label: "Antwortzahl",
                    sortable: true
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            selectedFields: [
                "id",
                "title",
                "is_active",
                "disable_by_count",
                "disable_by_date",
                "actual_active",
                "tags",
                "modification_date",
                "form_url",
                "answer_sum"
            ],
            defaultSelectedFields: [
                "id",
                "title",
                "is_active",
                "disable_by_count",
                "disable_by_date",
                "actual_active",
                "tags",
                "modification_date",
                "form_url",
                "answer_sum"
            ],
            actions: {
                delete: "forms/deleteForm",
                deleteMulti: "forms/deleteMultipleForms"
            }
        };
    },
    computed: {
        ...mapGetters({
            busy: "forms/getBusy",
            forms: "forms/getForms",
            formName: "forms/getFormName",
            tagName: "formsTags/getTagName",
            tagsSelect: "formsTags/getTagsForSelect"
        }),
        dataAvailable() {
            return this.forms.length > 0;
        }
    },
    created() {
        this.fetch({ filter: this.filter });
        this.fetchTags();
    },
    methods: {
        ...mapActions({
            fetch: "forms/fetchForms",
            fetchTags: "formsTags/fetchTags",
            duplicate: "forms/duplicateForm"
        }),
        applyFilter() {
            this.fetch({ filter: this.filter });
        },
        openConfirmDuplicateModal(form_id) {
            this.duplicateId = form_id;
            this.$bvModal.show("confirm-duplicate-modal");
        },
        duplicateForm() {
            this.duplicate(this.duplicateId);
        },
        sortChanged(ctx) {
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
        }
    }
};
</script>
