export default {
    data() {
        return {
            tableFieldsIdentifier: "default"
        };
    },
    watch: {
        selectedFields: {
            handler(val) {
                console.log(val);
                this.$store.dispatch("tableFields/setTableFields", {
                    key: this.tableFieldsIdentifier,
                    value: val
                });
            },
            deep: true
        }
    },
    created() {
        let tableFields = this.$store.getters["tableFields/getTableFields"](
            this.tableFieldsIdentifier
        );
        console.log(tableFields);
        if (this.defaultSelectedFields && tableFields.length == 0) {
            tableFields = this.defaultSelectedFields;
        }

        this.selectedFields = tableFields;
    }
};
